<template>
  <div>
    <v-progress-linear
      :active="getLoaderStatus"
      indeterminate
      fixed
      top
      color="primary"
      height="5px"
      style="z-index: 500"
    ></v-progress-linear>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-navigation-drawer
      :value="getToggleSlider"
      :key="`offer-1`"
      fixed
      temporary
      :stateless="keepOpen"
      overlay-opacity="0.8"
      right
      width="80%"
      class="fill-height"
    >
      <v-toolbar outlined flat dense light class="navbar-fixed-header">
        <v-btn icon class="mr-1" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <h5 class="ml-5 text-uppercase">Update Subscription</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-col class="max-width" v-if="form && form.vendor">
          <h6 class="ml-2">Subscription Information</h6>
          <v-col>
            <v-text-field
              label="Organization"
              v-model="form.organization_name"
              readonly
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Venue"
              v-model="form.vendor.name"
              readonly
              disabled
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <v-date-picker
                v-model="form.period_start"
                :allowed-dates="getAllowedDates"
                @input="menu = false"
              ></v-date-picker>
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Start Date"
                  :value="form.period_start | moment('MMM DD YYYY')"
                  readonly
                  v-on="on"
                  flat
                ></v-text-field>
              </template>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="menu_date_end"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <v-date-picker
                v-model="form.period_end"
                :allowed-dates="getAllowedDates"
                @input="menu_date_end = false"
              ></v-date-picker>
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="End Date"
                  :rules="[validateDate]"
                  :value="form.period_end | moment('MMM DD YYYY')"
                  readonly
                  v-on="on"
                  flat
                ></v-text-field>
              </template>
            </v-menu>
          </v-col>
          <v-col>
            <v-select
              label="Billing Period"
              v-model="form.billing_period"
              :items="[
                { name: 'Monthly', value: 'monthly' },
                { name: 'Yearly', value: 'yearly' },
              ]"
              item-text="name"
              item-value="value"
              :rules="[getRules.required]"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Status"
              :items="[
                { name: 'Active', value: 'active' },
                { name: 'Pending', value: 'pending' },
                { name: 'Cancelled', value: 'cancelled' },
                { name: 'Paused', value: 'paused' },
              ]"
              item-text="name"
              item-value="value"
              v-model="form.status"
              :rules="[getRules.required]"
            ></v-select>
          </v-col>
          <v-btn color="primary" class="mt-5" @click="validate">
            <span>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Update Subscription
            </span>
          </v-btn>
        </v-col>
      </v-form>
    </v-navigation-drawer>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <v-main
      :class="{ 'detail-content-margin': listingDrawer }"
      :style="[mini ? { 'padding-left': '56px' } : { 'padding-left': '232px' }]"
      class="details-content"
    >
      <v-col v-if="getAuthUser.user_type === 'admin'">
        <v-card class="ma-5" elevation="15">
          <div>
            <div class="pt-4 pl-3 text-h6 ma-2">Subscriptions</div>
            <v-divider class="mt-8 mb-4" color="default"></v-divider>
            <v-row class="ma-2">
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <organization-selector
                  @org-selected="orgSelected"
                  label="Select Organization"
                ></organization-selector>
              </v-col>
            </v-row>

            <v-divider class="my-4" color="default"></v-divider>
            <v-data-table
              :loading="false"
              :search="search"
              :headers="headers"
              :items="filteredSubscriptions"
              :server-items-length="getPaginationMeta.total"
              :options.sync="options"
              class="elevation-1"
            >
              <template v-slot:item.period_start="{ item }">
                {{ moment(item.period_start).format("MMM DD YYYY") }} -
                {{ moment(item.period_end).format("MMM DD YYYY") }}
              </template>

              <template v-slot:item.vendor="{ item, index }">
                <v-chip
                  small
                  label
                  :key="index"
                  color="primary"
                  class="my-1 ml-2"
                  dark
                  >{{ item.vendor.name }}</v-chip
                >
              </template>

              <template v-slot:item.subscribed_on="{ item }">
                {{ moment(item.subscribed_on).format("MMM DD YYYY") }}
              </template>

              <template v-slot:item.billing_period="{ item }">
                {{ item.billing_period | ucfirst }}
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip
                  small
                  label
                  :color="item.status | statusColor"
                  class="my-1 ml-2"
                  dark
                  >{{ item.status | status }}</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="editSubscription(item.id)"
                  >mdi-pencil</v-icon
                >
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col v-else class="text-center">
        <p class="body-2">No Access</p>
      </v-col>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import Snackbar from "@/components/Snackbar";
const TopNavBar = () =>
  import(/* webpackChunkName: "top-nav-bar" */ "@/components/TopNavBar");
export default {
  name: "Subscriptions",
  components: { TopNavBar, Snackbar },
  data: () => ({
    options: {},
    mini: false,
    listingDrawer: true,
    search: "",
    selectedOrganizationId: null,
    headers: [
      { text: "Organization", value: "organization_name", sortable: false },
      { text: "Venues", value: "vendor", sortable: false },
      { text: "Current Period", value: "period_start", sortable: false },
      { text: "Billing Period", value: "billing_period", sortable: false },
      { text: "Subscribed On", value: "subscribed_on", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: 80 },
    ],
    keepOpen: true,
    form: new Form(),
    snackbarMessage: null,
    validForm: true,
    menu: false,
    menu_date_end: false,
  }),
  watch: {
    options: {
      handler(obj) {
        if (obj.page <= this.getPaginationMeta.last_page) {
          obj.query = this.search;
          this.loadSubscriptions(obj);
        } else {
          this.options.page = this.getPaginationMeta.current_page;
        }
      },
      deep: true,
    },
    search: _.debounce(function (query) {
      this.options.query = query;
      this.options.page = 1;
      this.loadSubscriptions(this.options);
    }, 550),
  },
  async mounted() {
    //this.loadSubscriptions({page : this.page});
    this.loadOrganizations();
    this.initForm();
  },
  methods: {
    moment,
    orgSelected(orgId) {
      this.selectedOrganizationId = orgId;
    },
    initForm() {
      let subscription = this.getSubscription;
      this.form = new Form(subscription);
    },
    minimizeList(status) {
      this.mini = status;
    },
    close() {
      this.initForm();
      this.setToggleSlider(false);
    },
    async editSubscription(id) {
      await this.setSubscriptionById(id);
      await this.loadSubscription;
      this.initForm();
      this.setToggleSlider(true);
    },
    getAllowedDates(a) {
      if (moment(a) >= moment()) {
        return true;
      } else {
        return false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.update();
      }
    },
    async update() {
      if (this.$refs.form.validate()) {
        let subscription = Object.assign({}, this.form);
        this.setSubscription(subscription);
        await this.updateSubscription();
        if (this.getSubscriptionUpdateStatus === 2) {
          this.setToggleSlider(false);
          this.snackbarMessage = "Subscription Updated Successfully";
          this.$refs.snackbar.type = "success";
          this.$refs.snackbar.show = true;
          this.close();
        }
      }
    },
    validateDate() {
      if (
        moment(this.form.period_start).format() >
        moment(this.form.period_end).format()
      ) {
        return "End Date can't be prior to start date";
      } else {
        return true;
      }
    },

    realtimeSearch(array, value) {
      if (!value && value.length) {
        return array;
      }
      return array.filter((index) => {
        for (const key in index) {
          if (
            index[key] &&
            (index[key] == value ||
              index[key].toString().toLowerCase().includes(value.toLowerCase()))
          ) {
            return true;
          }
        }
        return false;
      });
    },

    ...mapActions([
      "logout",
      "loadSubscriptions",
      "loadSubscription",
      "loadOrganizations",
      "updateSubscription",
    ]),
    ...mapMutations([
      "setToggleSlider",
      "setSubscriptionById",
      "setSubscription",
    ]),
  },
  computed: {
    filteredSubscriptions() {
      let subscriptions = this.getSubscriptions;
      if (this.selectedOrganizationId !== null) {
        subscriptions = _.filter(subscriptions, {
          organization_id: this.selectedOrganizationId,
        });
      }
      //subscriptions = this.realtimeSearch(subscriptions, this.search);

      return _.orderBy(subscriptions, ["created_at"], ["desc"]);
    },
    ...mapGetters([
      "getAuthUser",
      "getSubscriptions",
      "getPaginationMeta",
      "getToggleSlider",
      "getSubscriptionsLoadStatus",
      "getLoaderStatus",
      "getOrganizations",
      "getSubscription",
      "getRules",
      "getSubscriptionUpdateStatus",
    ]),
  },
};
</script>

<style scoped>
</style>
<style>
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  min-width: 0 !important;
}
/* .v-data-footer__select {
  visibility: hidden;
} */
</style>
